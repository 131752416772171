import styled from "styled-components"

export default styled.p`
  font-size: 1.2rem !important;
  letter-spacing: 0.2px;
  margin: 0;
  color: #ddd;
  text-align: center;
  width: 75%;
  min-width: 320px;
  font-weight: 700;

  @media (max-width: 1000px) {
    width: 65%;
    font-size: 0.9rem !important;
  }

  @media (max-width: 876px) {
    width: 85%;
  }

  @media (max-width: 576px) {
    width: 90%;
    font-size: 0.85rem !important;
  }

  @media (max-width: 400px) {
    width: 100%;
    font-size: 0.8rem !important;
    margin-bottom: 0.8rem;
  }
`