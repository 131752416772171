import React, { Component } from "react"

export class PricingTable extends Component {
  render() {
    return (
      <div className="container clearfix">
        <div className="heading-block center mt-0 divcenter nobottomborder clearfix">
          <h2>A SUBSCRIPTION THAT SCALES WITH YOUR BUSINESS</h2>
          <p>
            M8 Systems offers tiered pricing for all components. WaterGuard
            In-Takes and Zones are also available in flange form.
          </p>
        </div>
        <table className="table table-hover table-comparison nobottommargin">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Basic</th>
              <th>Standard</th>
              <th>Pro</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Base Cost</td>
              <td>$39.99</td>
              <td>$69.99</td>
              <td>$99.99</td>
            </tr>
            <tr>
              <td>Included Device Connections (1)*</td>
              <td>1 In-Take + 1 Zone</td>
              <td>1 In-Take + 1 Zone</td>
              <td>2 In-Takes + 2 Zones</td>
            </tr>
            <tr>
              <td>Desktop Dashboard &amp; Mobile App</td>
              <td>
                <i className="icon-ok" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
            </tr>
            <tr>
              <td>Remote Valve Control</td>
              <td>
                <i className="icon-ok" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
            </tr>
            <tr>
              <td>Leak Detection (Auto-Shutdown)</td>
              <td>
                <i className="icon-ok" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
            </tr>
            <tr>
              <td>Smart Irrigation Scheduling</td>
              <td>
                <i className="icon-remove" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
            </tr>
            <tr>
              <td>Real-Time Sensor Data</td>
              <td>
                <i className="icon-remove" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
            </tr>
            <tr>
              <td>Online &amp; Phone Support</td>
              <td>
                <i className="icon-remove" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
            </tr>
            <tr>
              <td>Multi-Ranch Management</td>
              <td>
                <i className="icon-remove" />
              </td>
              <td>
                <i className="icon-remove" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
            </tr>
            <tr>
              <td>Water Cost Tracking</td>
              <td>
                <i className="icon-remove" />
              </td>
              <td>
                <i className="icon-remove" />
              </td>
              <td>
                <i className="icon-ok" />
              </td>
            </tr>
            <tr>
              <td>On-Site Support</td>
              <td>
                <i className="icon-remove" />
              </td>
              <td>1/Year</td>
              <td>4/Year</td>
            </tr>
          </tbody>
        </table>
        <div className="col-12 m8-pricing-elip">
          <i>
            *Additional WaterGuard Zones can be added for a $9.45 per month
            charge.
          </i>
        </div>
      </div>
    )
  }
}

export default PricingTable
