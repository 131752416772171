import React, { useLayoutEffect, useState } from "react"
import styled from "styled-components"
import { Carousel } from "react-bootstrap"

import analytics from "../../static/assets-m8/images/product/analytics.png"
import soilMoisture from "../../static/assets-m8/images/product/soil-moisture.png"
import waterSessions from "../../static/assets-m8/images/product/water-sessions.png"

const maxCarouselWidth = 600

const StyledContainer = styled.div`
  background-color: inherit;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: -120px 20px 0;
  align-self: center;

  @media (max-width: ${maxCarouselWidth}px) {
    width: 70%;
    justify-content: center;
  }
`

const StyledSeparator = styled.div`
  background-color: #fff;
  padding: 6px;
`

const AppImage = styled.img`
  max-width: calc((1 / 3.5) * 100%);
  border-radius: 10px;
  margin: 0 auto;

  @media (max-width: ${maxCarouselWidth}px) {
    min-width: 225px;
    max-width: 225px;
  }
`

function AppScreens() {
  const [showCarousel, setShowCarousel] = useState(() => {
    if (typeof window === "undefined") {
      return false
    }
    return window.innerWidth <= 768
  })

  // Using useLayoutEffect since it runs synchronously immediately after all DOM
  // mutations have been performed, so we can grab measurements
  useLayoutEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    function handleResize() {
      if (window.innerWidth <= maxCarouselWidth) {
        setShowCarousel(true)
        return
      }
      setShowCarousel(false)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <>
      <StyledContainer>
        {showCarousel ? (
          <Carousel touch interval={3000}>
            <Carousel.Item>
              <AppImage src={analytics} />
            </Carousel.Item>
            <Carousel.Item>
              <AppImage src={soilMoisture} />
            </Carousel.Item>
            <Carousel.Item>
              <AppImage src={waterSessions} />
            </Carousel.Item>
          </Carousel>
        ) : (
          <>
            <AppImage src={analytics} />
            <AppImage src={soilMoisture} />
            <AppImage src={waterSessions} />
          </>
        )}
      </StyledContainer>
      <StyledSeparator></StyledSeparator>
    </>
  )
}

export default AppScreens
