import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ScrollOut from "scroll-out"
import styled from "styled-components"

const StyledContainer = styled.div`
  background: url("/assets-m8/images/svg/layer1.svg");
  background-size: 100% 100%;
  background-position: bottom;
  padding-top: 50px;
`

const StyledHeading = styled.h2`
  font-size: 2rem;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const StyledSubHeading = styled.span`
  display: block;
  max-width: 700px;
  font-size: 1.2rem;
  margin-top: 10px;
  font-weight: 400;
  color: #555;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`

const StyledLineMark = styled.div`
  margin: 30px auto 0;
  content: "";
  display: block;
  margin-top: 30px;
  width: 40px;
  border-top: 2px solid #444;
`
export class Features extends Component {
  componentDidMount() {
    ScrollOut({
      onShown: function(el) {
        // el.classList.add("animated", "fadeIn", "slow")
      },
    })
  }

  render() {
    return (
      <StyledContainer>
        <div className="container clearfix" id="section-product">
          <div className="col-md-12 text-center">
            <StyledHeading>M8 Systems WaterGuard™ Features</StyledHeading>
            <StyledSubHeading>
              Control your irrigation system from your fingertips.
            </StyledSubHeading>
            <StyledLineMark />
          </div>
          {/* This block for three featurew is for mobile/smaller screen views above the picture*/}
          <div className="row">
            <div className="col-lg-4 col-md-12 d-block d-md-block d-lg-none bottommargin">
              <div className="feature-box topmargin" data-scroll>
                <div className="fbox-icon">
                  <i className="icon-info1" />
                </div>
                <h3>Leaks</h3>
                <p>
                Automated leak detection and shutoff of lines with immediate text notification
                </p>
              </div>
              <div className="feature-box topmargin" data-scroll>
                <div className="fbox-icon">
                  <i className="icon-robot" />
                </div>
                <StyledHeading>Accuracy</StyledHeading>
                <p>
                {/* Multiple tenants share well source, guess work knowing who used what water; 
                worse when leaks (no accountability). New regulatory requirements.  
                Need accurate accounting of what water goes where */}
                Precise irrigation by volume of water or by amount of time
                </p>
              </div>
              <div className="feature-box topmargin" data-scroll>
                <div className="fbox-icon">
                  <i className="icon-toolbox" />
                </div>
                <h3>Labor</h3>
                <p>
                {/* Spending 4-5x the labor needed to manual turn valves and pumps on/off, throughout the season.  
                Need automation. */}
                Remote control of valves, saving manual labor costs 
                </p>
              </div>
            </div>
            {/* This block of three features is for desktop views to the left of the picture*/}
            <div className="col-lg-4 col-md-12 d-none d-lg-block d-xl-block bottommargin">
              <div className="feature-box fbox-right topmargin" data-scroll>
                <div className="fbox-icon">
                  <i className="icon-info1" />
                </div>
                <StyledSubHeading>Leaks</StyledSubHeading>
                <p>
                Automated leak detection and shutoff of lines with immediate text notification
                <br></br>
                </p>
              </div>
              <div className="feature-box fbox-right topmargin" data-scroll>
                <div className="fbox-icon">
                  <i className="icon-robot" />
                </div>
                <StyledSubHeading>Accuracy</StyledSubHeading>
                <p>
                {/* Multiple tenants share well source, guess work knowing who used what water; 
                worse when leaks (no accountability). New regulatory requirements.  
                Need accurate accounting of what water goes where */}
                Precise irrigation by volume of water or by amount of time
                </p>
              </div>
              <div className="feature-box fbox-right topmargin" data-scroll>
                <div className="fbox-icon">
                  <i className="icon-toolbox" />
                </div>
                <StyledSubHeading>Labor</StyledSubHeading>
                <p>
                {/* Spending 4-5x the labor needed to manual turn valves and pumps on/off, throughout the season.  
                Need automation. */}
                Remote control of valves, saving manual labor costs 
                </p>
              </div>
            </div>
            <div className="col-lg-4 d-xl-none d-xl-block bottommargin center align-self-center">
              <Img
                fluid={this.props.data.file.childImageSharp.fluid}
                placeholderClassName="waterguard-image"
                alt="M8 Systems Dashboard Screenshot on iPhone X"
              />
            </div>
            <div className="col-lg-4 col-md-12 bottommargin">
              <div className="feature-box topmargin" data-scroll>
                <div className="fbox-icon">
                  <i className="icon-browser" />
                </div>
                <StyledSubHeading>Browser &amp; Mobile Access</StyledSubHeading>
                <p>
                  {/* User friendly Dashboard, Irrigation Calendar for all zones,
                  Analytics, Budgeting, Accurate Maps */}
                  User friendly dashboard accessible by browser or app, with irrigation calendar, map, and analytics
                </p>
              </div>
              <div className="feature-box topmargin" data-scroll>
                <div className="fbox-icon">
                  <i className="icon-line-check" />
                </div>
                <StyledSubHeading>Usability</StyledSubHeading>
                <p>
                {/* Currently we have many sensors and controllers, from several brands purchased over time. 
                No way to see what’s happening from one dashboard. Need integration and single point of control.  */}
                Control and overview of sensors and valves all in one place
                </p>
              </div>
              <div className="feature-box topmargin" data-scroll>
                <div className="fbox-icon">
                  <i className="icon-line-signal" />
                </div>
                <StyledSubHeading>Farm Ruggedness</StyledSubHeading>
                <p>
                {/* Today’s sensors and controllers require powerlines, signal boosters across farm, signal repeaters after a few hundred yards.  
                Need reliable, long distance communications from self-powered devices. */}
                Reliable, long distance communication from self-powered devices, with no need for signal boosters or powerlines  
                </p>
              </div>
            </div>
          </div>
        </div>
      </StyledContainer>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "waterguard/M8-Systems-WaterGuard-3in-Low.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Features data={data} {...props} />}
  />
)
