import React, { Component } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
// import Jumbotron from "react-bootstrap/Jumbotron"
import styled from "styled-components"
import { Link as ScrollLink } from "react-scroll"

const StyledBackground = styled.div`
  background: url("/assets-m8/images/landing/background.jpg");
  background-size: 100% 100%;
  background-position: bottom;
`

const StyledContainer = styled.div`
  height: calc(100vh - 70px);

  // For mobile screens removing the 100% of viewport
  @media (max-width: 992px) {
    height: 100%;
  }
`

const StyledRow = styled(Row)`
  min-height: 100%;
`

const StyledCol = styled(Col)`
  min-height: 100%;

  @media (max-width: 992px) {
    padding-top: 50px;
  }
`

const Styledh1 = styled.h1`
  font-size: 1.4rem !important;
  line-height: 1.2;
  margin: 2px;


  @media (max-width: 992px) {
    font-size: 1.5rem !important;
    text-align: left;
    width: 100%;
  }
`

const Styledh2 = styled.h2`
  font-size: 1.4rem !important;
  line-height: 1.4;


  @media (max-width: 992px) {
    font-size: 1.4rem !important;
    text-align: left;
    width: 100%;
  }
`

const StyledP = styled.p`
  font-size: 1.2rem !important;
  font-weight: 300;


  @media (max-width: 992px) {
    font-size: 1.2rem !important;
    text-align: left;
  }
`

const StyledImage = styled(Image)`
    image-size: 70%; 
  @media (max-width: 992px) {
    // width: 374.5px;
    // height: 454.5px;
    size: 70%; 
    
  }
`

export class Views extends Component {
  render() {
    return (
      <StyledBackground className="container-fluid">
        <StyledContainer className="container">
          <StyledRow>
            <StyledCol
              xs={12}
              lg={6}
              className="d-flex flex-column justify-content-center align-items-start flex-wrap"
            >
              <Styledh1>
                  <br />
                 How it Works  
                <br/> 
              </Styledh1> 
              {/* <Styledh2>
                Precise Water Management
              </Styledh2> */}
              <StyledP>
              Manage all devices with confidence, a cloud based dashboard allowing visibility into your irrigation at all times. 
              </StyledP>
              {/* <UpdatesButton
                className="button button-border-primary"
                rel="noopener noreferrer"
                href="http://act4value-1.hubspotpagebuilder.com/m8-systems"
                target="_blank"
                data-easing="easeInOutExpo"
                data-speed={1250}
                data-offset={70}
              >
                Request Demo
              </UpdatesButton> */}
            </StyledCol>
            <StyledCol
              xs={12}
              lg={6}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <StyledImage
                src="/assets-m8/images/landing/iPad-farm-map.png"
                width="659"
                height="800"
              />
            </StyledCol>
          </StyledRow>
        </StyledContainer>
      </StyledBackground>
    )
  }
}

export default Views
