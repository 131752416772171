import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import "animate.css"
import ScrollOut from "scroll-out"
import styled from "styled-components"
import Img from "gatsby-image"
import { Heading } from '../components/common/Heading';
import { Element } from "react-scroll"


const StyledImageContainer = styled.div`
  margin: auto;
`

const StyledContainer = styled.div`
  padding-top: 80px;
`

const StyledSubHeading = styled.span`
  display: block;
  max-width: 700px;
  font-size: 1.125rem;
  font-weight: 300;
  color: #555;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const StyledLineMark = styled.div`
  margin: 30px auto 0;
  content: "";
  display: block;
  margin-top: 30px;
  width: 40px;
  border-top: 2px solid #444;
`


export class Dashboard extends Component {
  componentDidMount() {
    ScrollOut({
      onShown: function(el) {
        // el.classList.add("animated", "fadeIn", "slow")
      },
    })
  }

  render() {
    return (
      <Element name="dashboard">
        <StyledContainer className="container clearfix">
          <div className="row">
            <div className="col-md-12 text-center">
              <Heading text='How It Works' />
              <StyledSubHeading>
                Manage all farms anytime, anywhere through the new gold standard for farm automation with powerful AI technology to connect all farm processes under one unified dashboard based in the cloud.
              </StyledSubHeading>
              <StyledLineMark />
            </div>
          </div>
          <StyledImageContainer className="col-md-10">
            <Img
              fluid={this.props.data.file.childImageSharp.fluid}
              alt="M8 Systems Dashboard on a MacBook and an iphone"
            />
          </StyledImageContainer>
        </StyledContainer>
      </Element>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "dashboard/new-dash-mockup.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Dashboard data={data} {...props} />}
  />
)
