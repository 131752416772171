import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ScrollOut from "scroll-out"
import styled from "styled-components"
import { Element } from "react-scroll"
import { Heading } from "../common/Heading"
import WaterGuardFeatures from "./WaterGuardFeatures"

const StyledImageContainer = styled.div`
  padding: 6rem;

  @media (max-width: 768px) {
    padding: 0 2rem;
  }
`

const StyledHeadingContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`

const StyledContainer = styled.div`
  background: url("/assets-m8/images/svg/layer2.svg");
  background-size: 100% 100%;
  background-position: bottom;
  background-color: #222;
  color: #fff;
`

const StyledWaterGuardContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 40px;

  .content {
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    margin-bottom: -650px;
  }
`

const StyledLineMark = styled.div`
  margin: 30px 0 auto 0;
  content: "";
  display: block;
  margin-top: 30px;
  width: 40px;
  border-top: 2px solid #444;
`

const StyledParagraph = styled.div`
  font-size: 1rem;
`

export class WaterGuard extends Component {
  componentDidMount() {
    ScrollOut({
      targets: ".scrollOut-target",
      onShown: function(el) {
        // el.classList.add("animated", "fadeIn", "slow")
      },
    })
  }

  render() {
    return (
      // <Element name="products">
      <StyledContainer>
        <StyledWaterGuardContainer className="container clearfix">
          <div className="row">
            <div className="content col-xl-5">
              {/* <h3 id="product-company-title">M8 Systems</h3> */}
              <h1 id="product-title">M8 WaterGuard™</h1>
              <h1 id="trade-mark">Every Drop Counts™</h1>
              <StyledHeadingContainer>
                <Heading text="Precise Water Management" />
                <StyledLineMark />
              </StyledHeadingContainer>
              <StyledParagraph>
                M8 Systems provides smart irrigation backed by our cloud-based
                platform to simultaneously reduce irrigation and labor costs for
                farms.
              </StyledParagraph>
            </div>
            <StyledImageContainer className="col-xl-7">
              <Img
                fluid={this.props.data.file.childImageSharp.fluid}
                className="scrollOut-target"
                placeholderClassName="waterguard-image"
                alt="M8 Systems WaterGuard Render"
              />
            </StyledImageContainer>
          </div>

          <WaterGuardFeatures />
        </StyledWaterGuardContainer>
      </StyledContainer>
      // </Element>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "product/full-system.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <WaterGuard data={data} {...props} />}
  />
)
