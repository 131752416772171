import React, { Component } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
// import Jumbotron from "react-bootstrap/Jumbotron"
import styled from "styled-components"
import { Link as ScrollLink } from "react-scroll"

const StyledBackground = styled.div`
  background: url("/assets-m8/images/landing/black-white-logos-background.png");
  background-size: 100% auto;
  background-position: bottom;
  background-size: cover !important; 
`

const StyledContainer = styled.div`
  height: 300px;

  // For mobile screens removing the 100% of viewport
  @media (max-width: 992px) {
    height: 100%;
  }
`

const StyledRow = styled(Row)`
  min-height: 100%;
`

const StyledCol = styled(Col)`
  min-height: 100%;
  color:#FFF;

  @media (max-width: 992px) {
    padding-top: 50px;
  }
`

const Styledh1 = styled.h1`
  font-size: 1.4rem !important;
  line-height: 1.2;
  margin: 2px;
  color:#FFF;

  @media (max-width: 992px) {
    font-size: 1.5rem !important;
    width: 100%;how t
    
  }
`

const Styledh2 = styled.h2`
  font-size: 1.4rem !important;
  line-height: 1.4;
  color:#FFF;


  @media (max-width: 992px) {
    font-size: 1.4rem !important;
    text-align: left;
    width: 100%;
  }
`

const StyledP = styled.p`
  font-size: 1.2rem !important;
  font-weight: 300;
  color:#FFF;


  @media (max-width: 992px) {
    font-size: 1.2rem !important;
    text-align: left;
  }
`


const StyledImage = styled(Image)`
  @media (max-width: 992px) {
    width: 374.5px;
    height: 454.5px;
  }
`

export class Logos extends Component {
  render() {
    return (
      <StyledBackground className="container-fluid">
        <StyledContainer className="container">
              <Styledh1 className="d-flex flex-column justify-content-center align-items-center">
                  <br />
                 Trusted by Americas Largest Growers
                <br/> 
              </Styledh1>
        </StyledContainer>
      </StyledBackground>
    )
  }
}

export default Logos
