import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Element } from "react-scroll"

const StyledContainer = styled.div`
  padding-top: 50px;
`

const StyledTitleContainer = styled.div`
  margin-bottom: 50px;
  margin-top: 50px;

  span {
    margin-bottom: 7px;
    color: #FF4D00 !important;
    font-size: 16px;
    font-family: 'Crete Round', serif;
    font-style: italic;
    font-weight: 400;
  }

  h3  {
    font-size: 26px;
    margin-bottom: 0px;
    font-weight: 700;
    //text-transform: uppercase;
    letter-spacing: 1px;
    color: #333;
  }
`

const StyledVideoContainer = styled.div`
  margin-bottom: 50px;
`

const StyledP = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  margin-left: 55px;
  margin-right: 55px;
`

export class Demo extends Component {
  render() {
    return (
      <Element name="solution">
        <StyledContainer className="container clearfix">
          <div className="row">
            <div className="col-md-12">
              <StyledTitleContainer>
                <span className="d-flex justify-content-center">
                  {/* Experience how the M8 WaterGuard™ functions. */}
                </span>
                <h3 className="text-center">Experience how the M8 WaterGuard™ functions.</h3>
              </StyledTitleContainer>
              <StyledVideoContainer className="d-flex justify-content-center">
                <iframe
                  // src="https://player.vimeo.com/video/393815452"
                  // src="https://player.vimeo.com/video/510072989"
                  src="https://player.vimeo.com/video/510991637"
                  width="640"
                  height="360"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  allowfullscreen
                ></iframe>
              </StyledVideoContainer>
              <StyledP className="text-center">
                M8 WaterGuard™ safeguards crops from damage and protects the
                farmer from financial risk. M8 Systems provides a precise
                irrigation calendar for every zone, allowing
                the farmer to be in total control from the touch of a smart device
                24hrs a day.
              </StyledP>
            </div>
          </div>
        </StyledContainer>
      </Element>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        largeDesktopImage: file(
          relativePath: {
            eq: "demo-video-farmer/farmer-main-large-devices.jpg"
          }
        ) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        video: file(relativePath: { eq: "video.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 550, maxHeight: 310, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Demo data={data} {...props} />}
  />
)
