import React, { Component } from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import styled from "styled-components"
import { Link as ScrollLink } from "react-scroll"

const StyledNavbar = styled(Navbar)`
  min-height: 70px;
  text-transform: uppercase;
  background-color: #333 !important;

  *:hover {
    color: #cf3400 !important;
  }
`

const StyledNavbarToggle = styled(Navbar.Toggle)`
  color: #eee !important;
  border-color: #333 !important;
`

export class HeaderScroll extends Component {
  render() {
    return (
      <StyledNavbar bg="dark" expand="lg" sticky="top">
        <Navbar.Brand>
          <Link className="standard-logo" to="/">
            <img
              src="/assets-m8/images/logo/m8-logo-color-horizontal-white.svg"
              height="23"
              width="200"
              alt="M8 Systems Logo"
            />
          </Link>
        </Navbar.Brand>
        <StyledNavbarToggle aria-controls="basic-navbar-nav">
          <i className="icon-reorder"></i>
        </StyledNavbarToggle>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link as="span">
              <Link className="m8-menu-list-li" to="/">
                <div>Home</div>
              </Link>
            </Nav.Link>
            <Nav.Link as="span">
              <div
                className="m8-menu-list-li products"
              >
                <a href="#farm-link-description">Solution</a>
              </div>
            </Nav.Link>
            
            {/* <Nav.Link as="span">
              <ScrollLink
                className="m8-menu-list-li pricing"
                activeClass="active"
                offset={-60}
                to="pricing"
                spy={true}
                smooth={true}
                duration={700}
                href="#"
              >
                <div>Pricing</div>
              </ScrollLink>
            </Nav.Link> */}
            {/* <Nav.Link as="span">
              <Link className="m8-menu-list-li" to="/news">
                <div>
                  News
                </div>
              </Link>
            </Nav.Link> */}
            <Nav.Link as="span">
              <Link className="m8-menu-list-li" to="/team">
                <div>Team</div>
              </Link>
            </Nav.Link>
            {/* <Nav.Link as="span">
              <ScrollLink
                className="m8-menu-list-li contact"
                activeClass="active"
                offset={0}
                to="contact"
                spy={true}
                smooth={true}
                duration={1000}
                href="#"
              >
                <div>Contact</div>
              </ScrollLink> 
              <a 
                className="m8-menu-list-li"
                href="https://mailchi.mp/m8systems.com/contact-us"
                >
                  <div>Contact</div>
              </a>
            </Nav.Link> */}
            <Nav.Link as="span">
              <a
                target="_blank"
                className="m8-menu-list-li orange"
                href="http://act4value-1.hubspotpagebuilder.com/m8-systems"
              >
                <div id="dashboard-link">Request Demo</div>
              </a>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </StyledNavbar>
    )
  }
}

export default HeaderScroll
