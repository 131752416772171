import React from "react"
import styled from "styled-components"

import serrato from "../../static/assets-m8/images/logo/serrato.png"
import perricone from "../../static/assets-m8/images/logo/perricone.png"
import grimmway from "../../static/assets-m8/images/logo/grimmway.png"
import sierraPacific from "../../static/assets-m8/images/logo/sierra-pacific.jpg"
import innovativeProduce from "../../static/assets-m8/images/logo/innovative-produce.png"

const StyledDiv = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0 4rem;
  background-color: inherit;
  width: 100%;

  p {
    color: #121212;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 50px;
    text-align: center;
  }

  div.farm-logos {
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: space-around;
    width: 85%;

    img {
      align-self: center;
      max-width: 120px;
      filter: drop-shadow(0 0 10px #aaa);
    }

    @media (max-width: 576px) {
      img {
        max-width: 90px;
      }
    }
  }

  @media (max-width: 576px) {
    p {
      font-size: 1.15rem;
      width: 100%;
    }

    img {
      max-width: 50px;
    }
  }
`

export default function CustomerRefs() {
  return (
    <StyledDiv>
      <p>Trusted by the largest growers</p>
      <div className="farm-logos">
        <img src={serrato} />
        <img src={grimmway} />
        <img src={perricone} />
        <img src={sierraPacific} />
        <img src={innovativeProduce} />
      </div>
    </StyledDiv>
  )
}
