import React, { Component } from "react"
import axios from "axios"
import styled from "styled-components"
import { Element } from "react-scroll"

const StyledContainer = styled.div`
  background: url("/assets-m8/images/svg/layer3.svg");
  background-size: 100% 100%;
  background-position: bottom;
`

const StyledRow = styled.div`
  margin-bottom: 10px;
`

export class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      subject: "",
      service: "Select",
      message: "",
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const name = target.name

    this.setState({ [name]: target.value })
  }

  handleSubmit(event) {
    event.preventDefault()

    axios
      .post("/sendEmail", {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        subject: this.state.subject,
        service: this.state.service,
        message: this.state.message,
      })
      .then(response => {
        var html =
          '<div class="alert alert-success">' +
          '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' +
          '<i class="icon-gift"></i><strong>Thank you!</strong> Your message has been successfully sent. Please expect a response within 24 hrs.' +
          "</div>"
        console.log("Client Side Response: ", response)
        document
          .getElementById("form-result")
          .insertAdjacentHTML("beforeend", html)
        document.getElementById("template-contactform").reset()
      })
      .then(() => {
        this.setState({
          name: "",
          email: "",
          phone: "",
          subject: "",
          service: "",
          message: "",
        })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  render() {
    return (
      <Element name="contact">
        <StyledContainer className="container-fluid">
          <div className="container clearfix">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-10 col-md-10">
                <h3 className="text-center">Send us an Email</h3>
                <div id="form-result" />
                <form
                  name="template-contactform"
                  action="#"
                  onSubmit={this.handleSubmit}
                >
                  {" "}
                  <StyledRow className="row">
                  <div className="col-md-12">
                      <label htmlFor="template-contactform-email">
                        Email <small>*</small>
                      </label>
                      <input
                        className="required email sm-form-control"
                        id="template-contactform-email"
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    </StyledRow>
                    <StyledRow className="row">
                    <div className="col-md-6">
                      <label htmlFor="template-contactform-name">
                        Name
                      </label>
                      <input
                        className="sm-form-control required"
                        id="template-contactform-name"
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="template-contactform-phone">Phone</label>
                      <input
                        className="sm-form-control"
                        id="template-contactform-phone"
                        type="text"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </StyledRow>
                  <StyledRow className="row">
                    <div className="col-md-12">
                      <label htmlFor="template-contactform-phone">Company Name</label>
                      <input
                        className="sm-form-control"
                        id="template-contactform-phone"
                        type="text"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </StyledRow>
                  <StyledRow className="row">
                    <div className="col-md-6">
                      <label htmlFor="template-contactform-phone">City</label>
                      <input
                        className="sm-form-control"
                        id="template-contactform-phone"
                        type="text"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="template-contactform-phone">State/Region</label>
                      <input
                        className="sm-form-control"
                        id="template-contactform-phone"
                        type="text"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </StyledRow>
                  <StyledRow className="row">
                    {/* <div className="col-md-7">
                      <label htmlFor="template-contactform-subject">
                        Subject <small>*</small>
                      </label>
                      <input
                        className="required sm-form-control"
                        id="template-contactform-subject"
                        type="text"
                        name="subject"
                        value={this.state.subject}
                        onChange={this.handleInputChange}
                      />
                    </div> */}
                    <div className="col-md-12">
                      <label htmlFor="template-contactform-service">
                        How can we help?
                      </label>
                      <select
                        className="sm-form-control"
                        id="template-contactform-service"
                        type="select"
                        name="service"
                        value={this.state.service}
                        onChange={this.handleInputChange}
                      >
                        <option value="Select">-- Select One --</option>
                        <option value="Sales">Product Information</option>
                        <option value="Sales">Sales</option>
                        <option value="Support">Support</option>
                        <option value="Sales">Partner Information</option>
                        <option value="Sales">Investor Inquiry</option>
                        <option value="Press Kit">Press Kit</option>
                        <option value="Sales">Other</option>
                      </select>
                    </div>
                  </StyledRow>
                  <StyledRow className="row">
                    <div className="col-md-12">
                      <label htmlFor="template-contactform-message">
                        Message <small>*</small>
                      </label>
                      <textarea
                        className="required sm-form-control"
                        id="template-contactform-message"
                        name="message"
                        rows={6}
                        cols={30}
                        value={this.state.message}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="col_full hidden">
                      <input
                        className="sm-form-control"
                        id="template-contactform-botcheck"
                        type="text"
                        name="template-contactform-botcheck"
                        defaultValue
                      />
                    </div>
                  </StyledRow>
                  <StyledRow className="row">
                  <div className="col-12 center">
                    <span>
                      M8 Systems, Inc. needs the information you provide to contact you about our products and services.
                      You may unsubscribe from these communications at any time.
                    </span>
                  </div>
                  </StyledRow>
                  <StyledRow className="row">
                  <div className="col-12 center">
                    <button
                      className="button button-3d nomargin"
                      id="template-contactform-submit"
                      type="submit"
                      name="submit"
                      value="submit"
                    >
                      Send Message
                    </button>
                  </div>
                  </StyledRow>
                  <input
                    type="hidden"
                    name="prefix"
                    defaultValue="template-contactform-"
                  />
                </form>
              </div>
            </div>
          </div>
        </StyledContainer>
      </Element>
    )
  }
}

export default Contact
