import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  padding: 1.5rem;
  color: #202020;
  --header-color: #121212;
`

const StyledHeading = styled.p`
  margin: 15px auto 50px;
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
  color: var(--header-color);

  @media (max-width: 576px) {
    font-size: 1.05rem;
    margin-bottom: 20px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 30px;

  @media (min-width: 577px) {
    ul {
      margin: 0;
    }
  }

  @media (max-width: 576px) {
    gap: 8px;
  }
`

const StyledDescription = styled.div`
  min-width: 300px;
  width: 25%;
  max-width: 600px;

  p:first-of-type {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: var(--header-color);
  }

  @media (max-width: 576px) {
    font-size: 0.9rem;
  }
`

function EveryDropCounts() {
  return (
    <StyledContainer>
      <StyledHeading>
        Every Drop Counts&trade;. Harnessing the Future of Precision
        Agriculture.
      </StyledHeading>
      <FlexContainer>
        <StyledDescription>
          <p>Reduced Cost of Operations</p>
          <ul>
            <li>
              Eliminate excess irrigation by factoring soil moisture
              automatically
            </li>
            <li>Reduce labor cost by over 50% with total automation</li>
            <li>Eliminate water waste by real time leak detection</li>
            <li>Cloud based control over farm infrastructure</li>
          </ul>
        </StyledDescription>
        <StyledDescription>
          <p>Better Yield by Using Our Platform</p>
          <ul>
            <li>Local microclimate</li>
            <li>Soil type, soil moisture</li>
            <li>Crop type</li>
            <li>Solar radiation, wind, temperature</li>
            <li>Soil temperature, relative humidity</li>
            <li>Analytics with AI Assist, data repository</li>
          </ul>
        </StyledDescription>
        <StyledDescription>
          <p>Fluid Flow Management with IoT Controller</p>
          <ul>
            <li>Connect any existing valves to the cloud</li>
            <li>Connect any existing pumps to the cloud</li>
            <li>No prior infrastructure is needed (power or Wi-Fi)</li>
            <li>Real time control & alerts</li>
            <li>Real time leak detection</li>
            <li>Manage any fluid such as oil, gas, water, and more</li>
          </ul>
        </StyledDescription>
      </FlexContainer>
    </StyledContainer>
  )
}

export default EveryDropCounts
