import React, { Component } from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    padding-top: 100px;
    padding-bottom: 700px;
  }
`

const StyledFeaturesContainer = styled.div`
  margin-bottom: 15px;
`
const StyledFeaturesPreHeading = styled.span`
  display: block;
  margin-bottom: 7px;
  font-size: 16px;
  font-family: "Crete Round", serif;
  font-style: italic;
  font-weight: 400;
  color: #999;
`

const StyledFeaturesHeading = styled.h3`
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0;
  letter-spacing: 1px;
  color: #333;
`

const StyledFeaturesContent = styled.p`
  font-size: 15px;
  color: #222;

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
      line-height: 1.75;
    }
  }
`

export class WaterGuardFeatures extends Component {
  render() {
    return (
      <StyledContainer className="container-fluid">
        <div className="container clearfix">
          <div className="row">
            <div className="col-lg-4">
              <i className="i-plain color i-large icon-calendar-check1 inline-block m8-product-detail-icons" />
              <StyledFeaturesContainer>
                {/* <StyledFeaturesPreHeading>
                  WaterGuard™ Sense Nodes
                </StyledFeaturesPreHeading> */}
                <StyledFeaturesHeading>
                  Smart Irrigation
                </StyledFeaturesHeading>
              </StyledFeaturesContainer>
              <StyledFeaturesContent>
                <ul>
                  <li>Easy to set, edit, and understand irrigation calendar</li>
                  <li>Systematic accurate water volume delivery</li>
                  <li>Environment aware - rain, humidity, soil moisture, water quality, fertilizers, crop type, geography, and weather</li>
                </ul>
              </StyledFeaturesContent>
            </div>
            <div className="col-lg-4">
              <i className="i-plain color i-large icon-piggy-bank inline-block m8-product-detail-icons" />
              <StyledFeaturesContainer>
                {/* <StyledFeaturesPreHeading>
                  WaterGuard™ Control Nodes
                </StyledFeaturesPreHeading> */}
                <StyledFeaturesHeading>
                  Reduce Cost of Operations
                </StyledFeaturesHeading>
              </StyledFeaturesContainer>
              <StyledFeaturesContent>
                <ul>
                  <li>24/7 Automated leak and clog detection with notification</li>
                  <li>Water quality detection with immediate alerts world wide and automatic shutoff</li>
                  <li>Wireless remote valve and pump control</li>
                </ul>
              </StyledFeaturesContent>
            </div>
            <div className="col-lg-4">
              <i className="i-plain color i-large icon-line-cloud-upload inline-block m8-product-detail-icons" />
              <StyledFeaturesContainer>
                {/* <StyledFeaturesPreHeading>
                  WaterGuard™ Gateway
                </StyledFeaturesPreHeading> */}
                <StyledFeaturesHeading>
                  24/7 Remote Accessibility
                </StyledFeaturesHeading>
              </StyledFeaturesContainer>
              <StyledFeaturesContent>
                <ul>
                  <li>Open and close valves anytime, from anywhere</li>
                  <li>Monitor irrigation directly from the website or app</li>
                  <li>Analytics and insights on farm data repository</li>
                </ul>
              </StyledFeaturesContent>
            </div>
          </div>
        </div>
      </StyledContainer>
    )
  }
}

export default WaterGuardFeatures
