import React from "react"
import styled from "styled-components"

import farmlink from "../../static/assets-m8/images/product/farmlink.jpeg"

const StyledContainer = styled.div`
  background-color: #ededef;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
`

const FarmLinkImg = styled.img`
  min-width: 225px;
  width: 50%;
  max-width: 275px;
  margin: 0 auto;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
`

const StyledNote = styled.p`
  color: #121212;
  text-align: center;
  font-weight: 600;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
  max-width: 800px;

  @media (max-width: 576px) {
    font-size: 0.8rem;
    width: 100%;
  }
`

const StyledList = styled.ul`
  color: #121212;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  width: 45%;
  max-width: 800px;

  & > div {
    margin: 0.1rem 0.8rem;
  }

  li {
    text-indent: -0.3rem;
  }

  @media (max-width: 876px) {
    width: 65%;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    gap: 0;
    width: 85%;
    font-size: 0.8rem;
  }

  @media (max-width: 400px) {
    flex-direction: column;
    gap: 0;
    width: 100%;
    font-size: 0.68rem;
  }
`

function FarmLinkCompatibility() {
  const compatibleValves = [
    "Hydraulic Valves (Latching solenoid)",
    "Gate Valves",
    "Motorized Ball Valves",
    "Butterfly Valves",
  ]

  return (
    <StyledContainer>
      <FarmLinkImg src={farmlink} />
      <StyledNote>
        Our solar-based FarmLink Universal IoT Controller comes complete with
        different battery sizes capable of controlling valves on pipes up to 16
        inches in diameter. The controller is compatible with a variety of
        irrigation pumps and valves including:
      </StyledNote>
      <StyledList>
        {compatibleValves.map(valveName => (
          <div>
            <li>{valveName}</li>
          </div>
        ))}
      </StyledList>
    </StyledContainer>
  )
}

export default FarmLinkCompatibility
