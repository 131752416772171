import React, { Component } from "react"

import Layout from "../components/Layout"
import HeaderScroll from "../components/Header/HeaderScroll"
import Landing from "../components/Landing"
import WaterGuard from "../components/WaterGuard/WaterGuard"
import Features from "../components/Features"
import Dashboard from "../components/Dashboard"
import Demo from "../components/Demo"
import Pricing from "../components/Pricing/Pricing"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import Views from "../components/Views"
import Logos from "../components/Logos"
import CustomerRefs from "../components/CustomerRefs"
import AppScreens from "../components/AppScreens"
import FarmLinkDescription from "../components/FarmLinkDescription"
import FarmLinkDisplay from "../components/FarmLinkDisplay"
import EveryDropCounts from "../components/EveryDropCounts"
import FarmLinkCompatibility from "../components/FarmLinkCompatibility"

export class index extends Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f5f5f7",
        }}
        className="clearfix"
      >
        <Layout />
        <HeaderScroll />

        <Landing />
        <AppScreens />
        <FarmLinkDescription />
        <FarmLinkDisplay />
        <EveryDropCounts />
        <FarmLinkCompatibility />

        {/* <Demo /> */}

        {/* <Dashboard /> */}
        {/* <Views /> */}
        {/* <WaterGuard /> */}
        {/* <Features /> */}
        {/* <Logos />         */}
        {/* <Pricing /> */}
        {/* <Contact /> */}

        {/* <CustomerRefs /> */}
        <Footer />

        {/* Go To Top */}
        <div className="icon-angle-up" id="gotoTop"></div>
      </div>
    )
  }
}

export default index
