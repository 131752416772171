import React, { Component } from "react"
import styled from "styled-components"

import SubHeading from "./styled/SubHeading"

const Styledh1 = styled.h1`
  font-family: Montserrat;
  font-size: 4.5rem;
  line-height: 1.2;
  margin: 0 auto;
  color: #fff;
  padding: 5rem 0 4rem;
  text-align: center;
  font-weight: 700;
  position: relative;
  z-index: 1;

  @media (max-width: 1200px) {
    font-size: 4rem !important;
  }

  @media (max-width: 1000px) {
    font-size: 3rem !important;
  }

  @media (max-width: 800px) {
    font-size: 2.5rem !important;
  }

  @media (max-width: 768px) {
    font-size: 2rem !important;
  }

  @media (max-width: 576px) {
    font-size: 1.625rem !important;
    width: 100%;
  }

  @media (max-width: 400px) {
    padding: 3.5rem 0 3.5rem;
    font-size: 1.5rem !important;
  }
`

const UpdatesButton = styled.a`
  border-radius: 20px !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.115rem;
  font-weight: 500;

  &:hover {
    background-color: #cf3400 !important;
  }

  @media (max-width: 992px) {
    align-self: center;
    border-radius: 20px !important;
  }
`

const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`

const LandingDiv = styled.div`
  margin: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("/assets-m8/images/landing/bkg-hero.png");
  padding-bottom: 175px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;

  @media (max-width: 576px) {
    gap: 1rem;

    #request-demo-button, #explore-product-link {
      font-size: 0.75rem;
    }
  }
`

const StyledCta = styled.div`
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 0.95rem;
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  a {
    &:hover {
      color: #e5e5e7;
    }
  }

  &:hover {
    #arrow {
      animation-name: move-up;
      animation-duration: 100ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }

  @keyframes move-up {
    from {
      top: 0px;
    }
    to {
      top: -3px;
    }
  }
`

const CtaUnderline = styled.span`
  display: block;
  width: 100%;
  background-color: #cf3400;
  height: 2px;
`

const Arrow = styled.div`
  border: solid #cf3400;
  border-width: 0 4px 4px 0;
  padding: 4px;
  position: relative;
  transform: rotate(-45deg);
`

export class Landing extends Component {
  render() {
    return (
      <LandingDiv>
        <div className="container-fluid">
          <div className="container">
            <Styledh1>
              <span style={{ color: "#0593b5" }}>Fluid Flow</span> Management.
              <br />
              Done right.
            </Styledh1>
            {/* <img style={{
              position: "absolute",
              top: "100px",
              left: "30%",
              width: "20%"
            }} src={leakingPipe} /> */}
            <FlexContainer>
              <SubHeading>
                M8 Systems is an all-encompassing smart agricultural platform
                that allows you to monitor your farm, detect leaks and conserve
                water through our cloud-based dashboard - anytime, anywhere,
                with a cell connection.
              </SubHeading>
              <ButtonsContainer>
                <UpdatesButton
                  id="request-demo-button"
                  className="button button-border-primary"
                  rel="noopener noreferrer"
                  href="http://act4value-1.hubspotpagebuilder.com/m8-systems"
                  target="_blank"
                  data-easing="easeInOutExpo"
                  data-speed={1250}
                  data-offset={70}
                >
                  Request Demo
                </UpdatesButton>
                <StyledCta>
                  <a id="explore-product-link" href="#farm-link-description">
                    Explore Product
                    <CtaUnderline></CtaUnderline>
                  </a>
                  <Arrow id="arrow"></Arrow>
                </StyledCta>
              </ButtonsContainer>
            </FlexContainer>
          </div>
        </div>
      </LandingDiv>
    )
  }
}

export default Landing
