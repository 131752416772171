import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  padding: 3rem 0 3rem;
  color: #202020;
  --header-color: #121212;
`

const StyledHeading = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 15px auto 1.25rem;
  color: var(--header-color);
  padding-right: 1rem;
  padding-left: 1rem;

  @media (max-width: 1000px) {
    font-size: 1.225rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`

const StyledSubHeading = styled.p`
  text-align: center;
  font-size: 1.2rem;
  @media (max-width: 768px) {
    font-size: 0.975rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 50px auto 0;
  gap: 8px;

  @media (max-width: 576px) {
    margin-top: 25px;
  }
`

const StyledSection = styled.div`
  min-width: 250px;
  width: 40%;
  max-width: 400px;

  p:first-of-type {
    font-weight: 700;
    margin-bottom: 0.75rem;
    color: var(--header-color);
  }
`

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  p {
    margin-bottom: 0;
  }
`

const StyledButton = styled.button`
  color: #cf3400;
  border: 2px solid #cf3400;
  border-radius: 9999px;
  text-transform: uppercase;
  padding: 10px;
  font-size: 0.7rem;
  font-weight: 500;
  transition: background-color 250ms ease-in-out;

  a {
    color: inherit !important;
  }

  &:hover {
    background-color: #e2e2e5;
  }
`

function FarmLinkDescription() {
  return (
    <>
      <span
        id="farm-link-description"
        style={{
          display: "block",
          marginTop: "-3rem",
          height: "3rem",
          visibility: "hidden",
        }}
      ></span>
      <StyledWrapper>
        <StyledHeading>
          FarmLink&trade; - The next-gen IoT Irrigation Management System for Ag
        </StyledHeading>
        <StyledSubHeading>
          Connecting all farm processes under one unified cloud-based dashboard.
        </StyledSubHeading>
        <FlexContainer>
          <StyledSection>
            <p>
              Keep your existing infrastructure, but enable total automation
            </p>
            <p>
              M8 Systems&apos; FarmLink Universal IoT Controller enables remote
              irrigation and fertigation management from mobile devices. The
              patented technology in our product eliminates the need to change
              the existing farm infrastructure. With FarmLink, simply connect
              your farm to the cloud!
            </p>
          </StyledSection>
          <StyledSection>
            <p>Enjoy the simplicity of an intuitive graphical interface</p>
            <p>
              M8 Systems &apos;AIoT&apos; Ag intelligence platform combines
              local sensors and controllers to simultaneously lower farming
              costs and increase crop output. By adding agronomy data to our
              wholistic platform, we empower farmers to have real time awareness
              and control over their land.
            </p>
          </StyledSection>
        </FlexContainer>
        <CtaContainer>
          <p>Ready to get started?</p>
          <StyledButton>
            <a
              rel="noopener noreferrer"
              href="http://act4value-1.hubspotpagebuilder.com/m8-systems"
              target="_blank"
            >
              Try a demo for free
            </a>
          </StyledButton>
        </CtaContainer>
      </StyledWrapper>
    </>
  )
}

export default FarmLinkDescription
