import React from "react";
import PropTypes from 'prop-types';
import './styles.css';

export function Heading({text, fontSize}) {
    return <h2 className='StyledHeading' style={{ fontSize }}>{text}</h2>
}

Heading.defaultProps = {
    fontSize: '1.6rem',
};
