import React from "react"
import styled from "styled-components"

import bgImage from "../../static/assets-m8/images/landing/background-behind-product.png"
import farmlinkClosed from "../../static/assets-m8/images/product/farmlink-ball-closed.png"
import farmlinkOpen from "../../static/assets-m8/images/product/farmlink-ball-open.png"

const StyledContainer = styled.div`
  background-image: url(${bgImage});
  background-size: cover;
  background-position: center;
  padding: 30px 0 30px;
`

const StyledHeading = styled.p`
  margin-bottom: 10px;
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;

  span {
    color: #cf3400;
    text-transform: none;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const StyledImage = styled.img`
  min-width: 320px;
  width: 40%;
  max-width: 600px;
`

function FarmLinkDisplay() {
  return (
    <StyledContainer>
      <StyledHeading>
        <span>FarmLink&trade;</span> Universal IoT Controller
      </StyledHeading>
      <FlexContainer>
        <StyledImage src={farmlinkClosed} />
        <StyledImage src={farmlinkOpen} />
      </FlexContainer>
    </StyledContainer>
  )
}

export default FarmLinkDisplay
