import React, { Component } from "react"
import Slider, { createSliderWithTooltip } from "rc-slider"
import "rc-slider/assets/index.css"
import styled from "styled-components"
import { Element } from "react-scroll"

import PricingTable from "./PricingTable"

const SliderWithTooltip = createSliderWithTooltip(Slider)

const StyledSliderContainer = styled.div`
  margin-bottom: 20px;
`

const StyledSliderWithTooltip = styled(SliderWithTooltip)`
  width: 90%;
`

export class Pricing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      basicPlanValue: 39.99,
      standardPlanValue: 69.99,
      proPlanValue: 99.99,
    }
  }

  onBasicSliderChange = value => {
    this.setState({ basicPlanValue: (39.99 + 9.45 * (value - 1)).toFixed(2) })
  }

  onStandardSliderChange = value => {
    this.setState({
      standardPlanValue: (69.99 + 9.45 * (value - 1)).toFixed(2),
    })
  }

  onProSliderChange = value => {
    this.setState({ proPlanValue: (99.99 + 9.45 * (value - 2)).toFixed(2) })
  }

  render() {
    return (
      <Element name="pricing">
        <section id="content">
          <div>
            <div
              className="section bg-angle notopmargin nobottommargin"
              id="pricing-body-container"
            >
              <PricingTable />
              <div className="container clearfix">
                <div
                  className="heading-block center mt-0 divcenter nobottomborder clearfix"
                  id="plan-comparison"
                >
                  <h2>Plan Comparison</h2>
                </div>
                <div className="row mb-2 align-items-stretch">
                  <div className="col-lg-8 col-md-8">
                    <div className="row align-items-stretch clearfix">
                      <div className="col-md-12">
                        {/* Basic Plan*/}
                        <div className="row align-items-stretch">
                          <div className="col-md-8 col-sm-8">
                            <div className="card mb-2 mb-sm-4">
                              <div className="card-body">
                                <div className="d-flex justify-content-between">
                                  <h4 className="mb-4">Basic</h4>
                                </div>
                                {/* Basic Plan Slider*/}
                                <StyledSliderContainer className="d-flex justify-content-center">
                                  <StyledSliderWithTooltip
                                    min={1}
                                    max={9}
                                    defaultValue={1}
                                    step={1}
                                    dots={false}
                                    trackStyle={{
                                      backgroundColor: "#fa4c06",
                                      height: 10,
                                      marginTop: 10,
                                    }}
                                    handleStyle={{
                                      borderColor: "#fa4c06",
                                      height: 28,
                                      width: 28,
                                      marginTop: 1,
                                      backgroundColor: "#fa4c06",
                                    }}
                                    railStyle={{
                                      backgroundColor: "#e3e3e3",
                                      height: 10,
                                      marginTop: 10,
                                    }}
                                    onChange={this.onBasicSliderChange}
                                    tipFormatter={value => `${value} Zone`}
                                  />
                                </StyledSliderContainer>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 d-none d-sm-flex card bgcolor text-white text-center mb-4">
                            <div className="card-body">
                              <h2 className="text-white mb-0 t700">$9.45</h2>
                              <p className="card-text mb-0 per-additional-zone">
                                $9.45{" "}
                                <span className="font-italic">
                                  per additional Zone
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Basic Plan Total*/}
                  <div className="col-lg-4 col-md-4 mt-md-0">
                    <div className="card text-center">
                      <div className="card-body-plan">
                        <h3 className="card-title t400">Your Basic Plan</h3>
                        <div className="pricing-price pricing-plan1 t600">
                          ${this.state.basicPlanValue}
                        </div>
                        <p className="card-text pricing-sub">Billed Monthly</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear mb-3 mb-sm-0" />
                <div className="row mb-2 align-items-stretch">
                  <div className="col-lg-8 col-md-8">
                    <div className="row align-items-stretch clearfix">
                      <div className="col-md-12">
                        {/* Standard Plan*/}
                        <div className="row align-items-stretch">
                          <div className="col-md-8 col-sm-8">
                            <div className="card mb-2 mb-sm-4">
                              <div className="card-body">
                                <div className="d-flex justify-content-between">
                                  <h4 className="mb-4">Standard</h4>
                                </div>
                                {/* Standard Plan Slider*/}
                                <StyledSliderContainer className="d-flex justify-content-center">
                                  <StyledSliderWithTooltip
                                    min={1}
                                    max={9}
                                    defaultValue={1}
                                    step={1}
                                    dots={false}
                                    trackStyle={{
                                      backgroundColor: "#fa4c06",
                                      height: 10,
                                      marginTop: 10,
                                    }}
                                    handleStyle={{
                                      borderColor: "#fa4c06",
                                      height: 28,
                                      width: 28,
                                      marginTop: 1,
                                      backgroundColor: "#fa4c06",
                                    }}
                                    railStyle={{
                                      backgroundColor: "#e3e3e3",
                                      height: 10,
                                      marginTop: 10,
                                    }}
                                    onChange={this.onStandardSliderChange}
                                    tipFormatter={value => `${value} Zone`}
                                  />
                                </StyledSliderContainer>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 d-none d-sm-flex card bgcolor text-white text-center mb-4">
                            <div className="card-body">
                              <h2 className="text-white mb-0 t700">$9.45</h2>
                              <p className="card-text mb-0 per-additional-zone">
                                $9.45{" "}
                                <span className="font-italic">
                                  per additional Zone
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Standard Plan Total*/}
                  <div className="col-lg-4 col-md-4 mt-md-0">
                    <div className="card text-center">
                      <div className="card-body-plan">
                        <h3 className="card-title t400">Your Standard Plan</h3>
                        <div className="pricing-price pricing-plan2 t600">
                          ${this.state.standardPlanValue}
                        </div>
                        <p className="card-text pricing-sub">Billed Monthly</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear mb-3 mb-sm-0" />
                <div className="row mb-2 align-items-stretch">
                  <div className="col-lg-8 col-md-8">
                    <div className="row align-items-stretch clearfix">
                      <div className="col-md-12">
                        <div className="row align-items-stretch">
                          <div className="col-md-8 col-sm-8">
                            <div className="card mb-2 mb-sm-4">
                              <div className="card-body">
                                <div className="d-flex justify-content-between">
                                  <h4 className="mb-4">Pro</h4>
                                </div>
                                {/* Pro Plan Slider*/}
                                <StyledSliderContainer className="d-flex justify-content-center">
                                  <StyledSliderWithTooltip
                                    min={2}
                                    max={9}
                                    defaultValue={2}
                                    step={1}
                                    dots={false}
                                    trackStyle={{
                                      backgroundColor: "#fa4c06",
                                      height: 10,
                                      marginTop: 10,
                                    }}
                                    handleStyle={{
                                      borderColor: "#fa4c06",
                                      height: 28,
                                      width: 28,
                                      marginTop: 1,
                                      backgroundColor: "#fa4c06",
                                    }}
                                    railStyle={{
                                      backgroundColor: "#e3e3e3",
                                      height: 10,
                                      marginTop: 10,
                                    }}
                                    onChange={this.onProSliderChange}
                                    tipFormatter={value => `${value} Zone`}
                                  />
                                </StyledSliderContainer>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 d-none d-sm-flex card bgcolor text-white text-center mb-4">
                            <div className="card-body">
                              {/* Per Additional Zone*/}
                              <h2 className="text-white mb-0 t700">$9.45</h2>
                              <p className="card-text mb-0 per-additional-zone">
                                $9.45{" "}
                                <span className="font-italic">
                                  per additionalZone
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Pro Plan Total*/}
                  <div className="col-lg-4 col-md-4 mt-md-0">
                    <div className="card text-center">
                      <div className="card-body-plan">
                        <h3 className="card-title t400">Your Pro Plan</h3>
                        <div className="pricing-price pricing-plan3 t600">
                          ${this.state.proPlanValue}
                        </div>
                        <p className="card-text pricing-sub">Billed Monthly</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Pricing Body End*/}
          </div>
        </section>
      </Element>
    )
  }
}

export default Pricing
